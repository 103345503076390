.App {
  background-color: #f7f8fa;
  height: 100vh;
  font-family: lato;
}
.panel--white {
  background-color: white;
  padding: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
}
.center-logo {
  justify-content: center;
}
