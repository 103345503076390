.page-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .login-container {
    width: 540px;
    margin: auto;
    .logo {
      width: 170px;
      margin-bottom: 30px;
    }
    h2 {
      margin-bottom: 30px;
    }
    .action-box {
      padding: 30px;

      .alert-box-outer {
        padding: 22px;
        margin-bottom: 35px;
        .alert-box {
          a:link {
            border-bottom: none;
            color: #28b667;
          }
        }
      }

      .ant-alert-success {
        background-color: #f6fbf7;

        border: 1px solid #28b667;
      }
      .header {
        font-size: 24px;
      }

      .label-margin {
        margin-bottom: 10px;
        .label {
          font-weight: bold;
          color: #313541;
        }
      }

      .ant-input {
        &:focus,
        &:hover {
          border-color: #d9d9d9;
          box-shadow: none;
        }
      }
      .ant-input-lg {
        font-size: 14px;
      }
      .box-footer {
        margin-top: 30px;

        button {
          width: 100%;
        }
      }

      .need-help {
        margin-top: 22px;
        color: #2a3233;

        font-size: 12px;
        width: fit-content;
        .dashed-line {
          border-bottom: 1.2px dashed #44565a;
          color: black;
          padding-bottom: 3px;
          cursor: pointer;
        }
      }
    }
    button {
      width: 180px;
    }
  }
}

//theme overrides
.ant-btn {
  box-shadow: none;

  &:hover,
  &:focus {
    color: #28b667;
    background-color: white;
    border-color: #28b667;
  }

  &.ant-btn-primary[disabled] {
    color: white;
    background-color: #28b667;
    border-color: #28b667;
    font-size: 13px;
    font-weight: 700;
    &:hover,
    &:focus {
      color: white;
      background-color: #3ebd76;
      border-color: #3ebd76;
    }
  }

  &.ant-btn-primary {
    color: white;
    background-color: #28b667;
    border-color: #28b667;
    font-size: 13px;
    font-weight: 700;

    &:hover,
    &:focus {
      color: white;
      background-color: #3ebd76;
      border-color: #3ebd76;
    }
  }

  &.ant-btn-link {
    color: #28b667;
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }
}
.ant-popover {
  width: 220px;
}

.ant-popover-inner-content {
  font-size: 11px;
}
